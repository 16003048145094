@import '../variables';

.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    height: $footer-height;
    align-items: center;
    justify-content: space-around;

    .footer-link{
        color: $light;
    }
}