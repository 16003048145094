@import 'variables';

//modules imports
@import 'modules/footer';
@import 'modules/about_card';
@import 'modules/buttons';
@import 'modules/navbar';
@import 'modules/episode_card';
@import 'modules/title_section';
@import 'modules/spinner';
@import 'modules/instruction_section';
@import 'modules/hero';
@import 'modules/token-feature';
@import 'modules/about-hosts-card';
//pages imports
@import 'pages/Subscribe';
@import 'pages/about';
@import 'pages/discord';
@import 'pages/guests';
@import 'pages/social';
@import 'pages/episodes';
@import 'pages/calendar';
@import 'pages/crap';
@import 'pages/home';

//
.global{

    font-family: 'Raleway', sans-serif;

    .a {
        color: $light;
    }

    .trashmasters{
        font-family: 'Grenze Gotisch', cursive;
    }

    font-size: large;

    .center {
        display: flex;
        justify-content: center;
    }

}