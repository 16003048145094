@import '../variables';
//     <!-- HTML !-->
// <button class="custom-button" role="button"><span class="text">Button 64</span></button>

/* CSS */

.custom-button{
  align-items: center;
  background-image: linear-gradient(144deg, $dark-red, $red 50%, $olive);
  border: 0;
  border-radius: 8px;
  box-shadow: $light 0 5px 15px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  min-height: 50px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.custom-button:hover{
  outline: $light solid 1px;
  color: $light;
  box-shadow: 0 0 30px 12px $light;
}

.custom-button:active,
.custom-button:hover {
  outline: 0;
}

.custom-button span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.custom-button:hover span {
  background: none;
}

@media (min-width: 768px) {
  .custom-button {
    font-size: 24px;
    min-width: 196px;
  }
}