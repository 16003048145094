@import '../variables';

.about {
    // color: black;
    // margin-left: 0;
    // margin-right: 0;
    // position: relative;
    // bottom: -$footer-height;
    // margin-bottom: 1rem;

    // .mobile{
    //     display: none;
    // }

    // .unskewed {
    //     padding: 2rem;
    //     transform: skewX(-45deg);
    // }

    // .picture {
    //     position: relative;
    //     right: 0;
    //     height: 100%;
    //     display: flex;
    //     align-items: flex-end;
    //     justify-content: flex-end;
    //     width: 50vw;
    // }

    // .row {
    //     width: 95vw;
    //     height: 30vh;
    //     margin-left: -8rem;
    //     margin-top: 2rem;
    //     display: flex;
    //     flex-direction: column;

    //     .skew-col {
    //         background-color: red;
    //         transform: skewX(45deg);
    //         height: 100%;
    //         width: 15vw;
    //         transition: width 2s;
    //         transition-timing-function: ease-out;
    //     }

    //     .name-text {
    //         color: $light;
    //         font-size: 2rem;
    //         text-align: end;
    //         position: absolute;
    //         bottom: 5px;
    //         right: 42px;
    //         padding: unset;
    //         margin: 3rem;
    //     }

    //     .text {
    //         display: none;
    //     }

    //     .name {
    //         background-color: black;
    //         width: 30vw;
    //         color: $light;
    //     }

    //     .info {
    //         background-color: $dark-red;
    //         color: transparent;
    //     }

    //     .roles {
    //         background-color: $red;
    //         color: transparent;
    //     }

    //     .skew-col:hover {
    //         width: 500px;
    //         color: $light;
    //         padding: 2rem;

    //         .text {
    //             display: flex;
    //         }
    //     }

    //     .name:hover {
    //         width: 30vw;
    //     }
    // }

    // .first {
    //     background-image: none;
    //     background-color: white;
    //     margin-left: 0;
    //     font-family: 'Grenze Gotisch', cursive;
    //     font-weight: 600;
    //     text-align: center;
    //     height: 10vh;
    // }

    // @media screen and (max-width: 480px) {
    //     .desktop{
    //         display: none;
    //     }

    //     .mobile{
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         margin-bottom: 2rem;

    //         .card{
    //             margin: 1rem;
    //         }
    //     }
    //   }

    //retaining the code from above for recreatability

    //all useable code is below

    .about-cards {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-content: center;

        .card {
            margin: 1rem;
            height: 40vh;
            transition: all .4s ease-in-out;
        }

        .card:hover {
            transform: scale(1.07);
        }

    }

    @media (max-width: 768px){
            .about-cards{
                display: flex;
                flex-direction: column;
            }
        }

    .pitch {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 2rem;
        padding: 2rem;
        background-color: $dark-red;
        border-radius: 20pt;
        color: $light;
        transition: all .4s ease-in-out;
        margin-bottom: 4rem;
    }

    .pitch:hover{
        transform: scale(1.07);
    }
}