@import '../variables';

.hero{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - $navbar-height);

    .btn{
        background-color: $olive;
        align-items: center;
        width: 90vw;
        height: min-content;
        padding: 1rem;
        border-radius: 20px;
        font-size: 2rem;
        color: $light;
        margin: 2rem;
    }

    .hero-header {
        margin: 2rem;
        font-size: 30pt;
        color: $light;
        text-align: center;
        text-shadow: 2px 2px $dark-red;
        font-weight: 600;
    }

    .red {
        background-color: $dark-red;
    }

    .sm{
        height: 50px;
        width: 100px;
        font-size: 20pt;
    }

    .md{
        height: 100px;
        width: 200px;
        font-size: 40pt;
    }

    .lg{
        height: 200px;
        width: 400px;
        font-size: 60pt;
    }

    .a:hover{
        color: $light;
    }

    .announcement{
        margin-top: 6vh;
    }
}