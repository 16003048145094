@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap");
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: black;
  color: white;
  height: 35px;
  align-items: center;
  justify-content: space-around;
}
.footer .footer-link {
  color: #f7f6fa;
}

.about-card {
  width: 20vw;
  margin: 2rem;
}

/* CSS */
.custom-button {
  align-items: center;
  background-image: linear-gradient(144deg, #730A13, #D9303E 50%, #B2AC58);
  border: 0;
  border-radius: 8px;
  box-shadow: #f7f6fa 0 5px 15px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  min-height: 50px;
  padding: 3px;
  text-decoration: none;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.custom-button:hover {
  outline: #f7f6fa solid 1px;
  color: #f7f6fa;
  box-shadow: 0 0 30px 12px #f7f6fa;
}

.custom-button:active,
.custom-button:hover {
  outline: 0;
}

.custom-button span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.custom-button:hover span {
  background: none;
}

@media (min-width: 768px) {
  .custom-button {
    font-size: 24px;
    min-width: 196px;
  }
}
.navigator {
  display: flex;
}
.navigator .brand {
  color: #f7f6fa;
  text-decoration: none;
  margin-right: 2rem;
}
.navigator .navbar-brand {
  font-family: "Grenze Gotisch", cursive;
}
.navigator .navbar-brand .a {
  color: #f7f6fa !important;
}
.navigator .nav-link .link {
  text-decoration: none;
  color: #f7f6fa;
}
.navigator .nav-link .link:hover {
  text-shadow: 0 0 11px white;
}

.episode-card {
  margin: 2.5rem;
  /* product specifications */
  /* checkout button*/
  /* animation */
}
.episode-card *,
.episode-card button,
.episode-card input {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.episode-card html,
.episode-card body {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(90deg, #414850, #131720);
  color: #D5E1EF;
}
.episode-card body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}
.episode-card .wrapper {
  width: 350px;
  border-radius: 40px;
  background-image: linear-gradient(120deg, #343A4F, #0F1620);
  overflow: hidden;
}
.episode-card .overviewInfo,
.episode-card .productSpecifications {
  padding: 24px;
  color: white;
}
.episode-card .overviewInfo {
  background-image: linear-gradient(176deg, #3D9DEA, #4A4EEE);
}
.episode-card .actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.episode-card .actions .cartbutton {
  position: relative;
}
.episode-card .actions .cartbutton::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-image: linear-gradient(90deg, #489be2, #0f629c);
  border-radius: 50%;
  position: absolute;
  top: 11px;
  right: 8px;
}
.episode-card .actions .cartbutton svg {
  color: rgba(171, 171, 171, 0.4509803922);
}
.episode-card .actions .backbutton,
.episode-card .actions .cartbutton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.episode-card .neurobutton {
  background-image: linear-gradient(120deg, #343A4F, #0F1620);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 3px 4px 5px 0px rgba(197, 197, 197, 0.1), inset 3px 6px 6px 5px rgba(78, 77, 77, 0.1), -2px -2px 8px 2px rgba(255, 255, 255, 0.1), 2px 2px 6px 3px rgba(0, 0, 0, 0.4);
}
.episode-card .productinfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 200px;
  margin-bottom: 50px;
}
.episode-card .productImage {
  position: absolute;
  width: 321px;
  height: auto;
  transition: ease 2s all;
}
.episode-card .productImage img {
  width: 70%;
  height: auto;
  border-radius: 15%;
}
.episode-card .productImage:hover {
  transition: ease 2s all;
  animation: none;
  transform: rotate(-70deg) translate(10px, 66px);
}
.episode-card h1 {
  font-family: "Michroma", sans-serif;
}
.episode-card .grouptext h3 {
  letter-spacing: 3.2px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.episode-card .grouptext p {
  font-size: 12px;
  opacity: 0.8;
}
.episode-card .featureIcon {
  width: 40px;
  height: 40px;
  background-image: linear-gradient(120deg, #343A4F, #0F1620);
  border-radius: 8px;
  margin-right: 16px;
}
.episode-card .productSpecifications h1 {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 32px;
}
.episode-card .productSpecifications p {
  opacity: 0.8;
  font-size: 15px;
  line-height: 1.5;
}
.episode-card .productSpecifications .productFeatures {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  grid-row-gap: 16px;
}
.episode-card .productSpecifications .productFeatures .feature {
  display: flex;
}
.episode-card .checkoutButton {
  display: flex;
  width: 100%;
  background-image: linear-gradient(120deg, #343A4F, #0F1620);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: -2px -2px 2px 0px rgba(80, 80, 80, 0.1), 2px 2px 3px 0px rgba(12, 12, 12, 0.3), inset 0px 0px 0px 2px rgba(80, 80, 80, 0.2);
  margin-top: 30px;
  padding: 14px;
  justify-content: space-between;
  align-items: center;
}
.episode-card .priceTag {
  display: flex;
  align-items: center;
  font-size: 32px;
}
.episode-card .priceTag .priceTag > * {
  fill: whitesmoke;
  height: 50px;
}
.episode-card .priceTag span {
  color: #488dc7;
  font-size: 20px;
}
.episode-card button.preorder {
  outline: 0;
  border: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: linear-gradient(85deg, #61c7ef, #4833fb);
  color: white;
}
.episode-card .preorder p {
  padding: 8px 17px;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
}
.episode-card .buttonaction {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.7);
}
@keyframes updowncontroller {
  0% {
    transform: rotate(-90deg) translate(-56px, 66px);
  }
  80%, 100% {
    transform: rotate(-70deg) translate(10px, 66px);
  }
}

.title-section {
  display: flex;
  padding: 2rem;
  font-family: "Grenze Gotisch", cursive;
  justify-content: center;
  font-size: 55pt;
}

.spinner {
  padding: 3rem;
  height: 80vh;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
}

.instruction-seciton {
  margin: 3rem;
}
.instruction-seciton .nav-btns {
  display: flex;
  margin-top: 1rem;
}
.instruction-seciton .nav {
  display: flex;
  justify-content: center;
}

.hero {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
}
.hero .btn {
  background-color: #B2AC58;
  align-items: center;
  width: 90vw;
  height: -moz-min-content;
  height: min-content;
  padding: 1rem;
  border-radius: 20px;
  font-size: 2rem;
  color: #f7f6fa;
  margin: 2rem;
}
.hero .hero-header {
  margin: 2rem;
  font-size: 30pt;
  color: #f7f6fa;
  text-align: center;
  text-shadow: 2px 2px #730A13;
  font-weight: 600;
}
.hero .red {
  background-color: #730A13;
}
.hero .sm {
  height: 50px;
  width: 100px;
  font-size: 20pt;
}
.hero .md {
  height: 100px;
  width: 200px;
  font-size: 40pt;
}
.hero .lg {
  height: 200px;
  width: 400px;
  font-size: 60pt;
}
.hero .a:hover {
  color: #f7f6fa;
}
.hero .announcement {
  margin-top: 6vh;
}

.token-feature {
  background-color: black;
  border-radius: 20px;
  margin-bottom: 3rem;
}
@media screen and (max-width: 820px) {
  .token-feature {
    display: none;
  }
}
.token-feature .slide .carousel-inner .carousel-item .token-image {
  max-height: 30vh;
  margin-left: 18rem;
  margin-right: 18rem;
  margin-bottom: 12rem;
}

.about-hosts-card {
  width: 16rem;
  margin: 10px;
  border-radius: 4px;
  padding: 1rem;
  border: solid black 1px;
  background-color: #4d4d50;
  color: white;
}
.about-hosts-card .gradient {
  background-image: linear-gradient(#D9303E, #730A13);
  height: 8rem;
  border-radius: 4px 4px 0px 0px;
}
.about-hosts-card .profile-down img {
  height: 16rem;
  border-radius: 15rem;
  margin-top: -6rem;
  padding: 5px;
  background: white;
}
.about-hosts-card .profile-down {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
.about-hosts-card .profile-description {
  padding: 1rem;
}
.about-hosts-card .profile-title {
  padding: 1rem;
  align-content: center;
  font-weight: 700;
  font-family: "Grenze Gotisch", cursive;
  font-size: xx-large;
}

.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;
}
.subscribe .subscription {
  margin: 2rem;
  text-align: center;
  font-size: 24pt;
}
.subscribe .subscription .icon {
  fill: white;
}

.about .about-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
}
.about .about-cards .card {
  margin: 1rem;
  height: 40vh;
  transition: all 0.4s ease-in-out;
}
.about .about-cards .card:hover {
  transform: scale(1.07);
}
@media (max-width: 768px) {
  .about .about-cards {
    display: flex;
    flex-direction: column;
  }
}
.about .pitch {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
  background-color: #730A13;
  border-radius: 20pt;
  color: #f7f6fa;
  transition: all 0.4s ease-in-out;
  margin-bottom: 4rem;
}
.about .pitch:hover {
  transform: scale(1.07);
}

.discord {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 75px;
}

.guests {
  display: flex;
  flex-direction: column;
}
.guests .version-selector {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.guests .version-selector .buttons-containter {
  margin: 1rem;
}
.guests .instruction-section {
  margin: 3rem;
}
.guests .instruction-section .nav-btns {
  display: flex;
  margin-top: 1rem;
}
.guests .instruction-section .nav {
  display: flex;
  justify-content: center;
}

.social {
  min-height: calc(100vh - 75px);
}
.social .body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.social .account {
  color: white;
  height: 20vw;
  width: 20vw;
  margin: 1rem;
  background-color: black;
  border-radius: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.social .account > div {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .social .body {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    align-items: center;
  }
  .social .account {
    height: 50vw;
    width: 50vw;
  }
}

.episodes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.calendar {
  padding-bottom: 35px;
}
.calendar .contact {
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.crap {
  background-color: black;
  height: 93vh;
  color: white;
}
.crap .accordion-item, .crap .accordion-header, .crap .accordion-button {
  background-color: grey;
  color: #f7f6fa;
}

.global {
  font-family: "Raleway", sans-serif;
  font-size: large;
}
.global .a {
  color: #f7f6fa;
}
.global .trashmasters {
  font-family: "Grenze Gotisch", cursive;
}
.global .center {
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=style.css.map */