@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@500&display=swap');

$navbar-height: 75px;
$footer-height: 35px;

//Colors
$black:     black;
$white:     white;
$dark-red:  #730A13;
$red:       #D9303E;
$olive:     #B2AC58;
$light:     #f7f6fa;
$grey:      #4d4d50;

//fonts
$grenz: 'Grenze Gotisch', cursive;