.instruction-seciton{
    margin: 3rem;

        .nav-btns{
            display: flex;
            margin-top: 1rem;

        }
        
        .nav{
            display: flex;
            justify-content: center;
        }
}